import http from '~/utils/http'


export class CommonApi {
  // @POST('/commonApi/sendCodeWithPhone')
  // Future<bool> sendCodeWithPhone(@Body() SendCodeParam param);
  static sendCodeWithPhone(params: SendCodeParam, opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/commonApi/sendCodeWithPhone',
      body: params
    })
  }

  // // 已迁移至 /jav/config/version
  // // @POST('/commonApi/appVersionCheck')
  // // Future<AppVersion> appVersionCheck(@Body() AppVersionParam param);
  static appVersionCheck(params: AppVersionParam, opt?: HttpOptions) {
    return http.post<AppVersion>({
      ...opt,
      url: '/commonApi/appVersionCheck',
      body: params
    })
  }

  // @POST('/commonApi/getCommonDict')
  // Future<String> getCommonDict();
  static getCommonDict(opt?: HttpOptions) {
    return http.post<string>({
      ...opt,
      url: '/commonApi/getCommonDict'
    })
  }

  // @POST('/jav/common/bizCooperation')
  // Future<List<BizCooperation>> bizCooperation();
  static bizCooperation(opt?: HttpOptions) {
    return http.post<BizCooperation>({
      ...opt,
      url: '/jav/common/bizCooperation'
    })
  }

  // @POST('/commonApi/uploadCertificateImg')
  // Future<UploadImageMod> uploadCertificateImg({@Part() required File file});
  static uploadCertificateImg(params: File, opt?: HttpOptions) {
    return http.post<UploadImageMod>({
      ...opt,
      url: '/commonApi/uploadCertificateImg',
      body: params
    })
  }

  // @POST('/jav/common/uploadJavImg')
  // Future<UploadImageMod> uploadJavImg({
  //   @Part() required File file
  // });
  static uploadJavImg(params: File, opt?: HttpOptions) {
    return http.post<UploadImageMod>({
      ...opt,
      url: '/jav/common/uploadJavImg',
      body: params
    })
  }
}
